<template>
  <div>
    <v-card-text>
      <h3>Fachliche Beschreibungen der Daten:</h3>
      <br />
      <p>
        <a :href="descriptionModelData" target="_blank">Beschreibung der Modelldaten</a>
      </p>
      <p>
        <a :href="descriptionResultData" target="_blank">Beschreibung der Ergebnisdaten</a>
      </p>

      <h3>Nutzungsbeschränkung</h3>
      <br />
      <p>
        Die auf den von hier verlinkten Seiten angebotenen Daten sind zur Nutzung für innerdienstliche, nicht
        kommerzielle Zwecke innerhalb des Geschäftsbereichs des MKULNV-NRW zur Erledigung von Aufgaben der
        Umweltverwaltung im Allgemeinen und von Aufgaben der Lärmkartierung nach der Umgebungslärmrichtlinie im
        Besonderen bestimmt.
      </p>
      <p>
        Eine Weitergabe der Daten an Dritte ist im Rahmen einer zweckgebundenen Auftragsbearbeitung im Rahmen der
        Lärmkartierung zulässig, ein eigenes Recht an den Daten wird hierdurch jedoch nicht erworben. Jedes darüber
        hinaus gehende Recht zur Weitergabe der Daten ist ausgeschlossen.
      </p>
      <p>
        Soweit es sich bei den angebotenen Daten um Daten handelt, die durch das Eisenbahnbundesamt zur Verfügung
        gestellt werden, ist ergänzend Folgendes zu beachten:
      </p>
      <ol class="mb-5">
        <li>
          Die Daten dürfen nur aufgrund einer Rechtsvorschrift oder soweit es für den genannten Nutzungszweck
          erforderlich ist, an Dritte weitergegeben werden;
        </li>
        <li>
          Durch den Nutzungsberechtigten muss sichergestellt werden, dass keine weiteren Personen Zugriff auf die
          bereitgestellten Daten nehmen können;
        </li>
        <li>
          Alle Personen, die zum Umgang mit den Daten berechtigt sind, dürfen die Daten nicht für eigene Zwecke nutzen.
        </li>
      </ol>
      <p>
        Soweit es sich bei den angebotenen Daten um Geobasisdaten der Landesvermessung handelt, nimmt die
        Bezirksregierung Köln, Geobasis NRW die Urheber- und Leistungsschutzrechte wahr.
      </p>
    </v-card-text>
  </div>
</template>
<script>
export default {
  /*data() {
    return {
      links: {
        descriptionModelData: `../../gdbs/docs/erlaeuterung_int_modell.pdf`,
        descriptionResultData: `../../gdbs/docs/erlaeuterung_int_ergeb.pdf`
      }
    };
  }, */
  computed: {
    descriptionModelData() {
      return `./docs/erlaeuterung_int_modell.pdf`;
    },
    descriptionResultData() {
      return `./docs/erlaeuterung_int_ergeb.pdf`;
    }
  }
};
</script>
